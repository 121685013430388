<template>
    <v-data-table
        :headers="table.headers"
        :items="table.rows"
        :items-per-page="15"
        class="elevation-1"
    />
</template>

<script>
    import ModelTable from './ModelTable';

    export default {
        name: 'DatasetViewerTable',

        props: {
            dataset: {
                type: Object,
                required: true,
            },
        },

        computed: {
            table() {
                return new ModelTable(this.dataset);
            },
        },
    };
</script>
