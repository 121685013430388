<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row v-if="dataset">
            <v-col cols=12>
                <v-alert
                    border="bottom"
                    colored-border
                    color="orange"
                    type="warning"
                    elevation="2"
                    v-if="status.success === false"
                >
                    {{ status.reason }}
                </v-alert>

                <v-card v-else>
                    <v-card-title>
                        {{ dataset.name  }}
                    </v-card-title>
                    <v-card-text>
                        {{ dataset.datatype }}

                    </v-card-text>
                    <viewer-table :dataset="dataset.data" />
                </v-card>
            </v-col>
        </v-row>
    </request>
</template>

<script>
    import DatastoreAPI from './webapi';
    import ViewerTable from './ViewerTable.vue';

    export default {
        name: 'DatasetViewer',

        components: {
            ViewerTable,
        },

        props: {
            datasetId: {
                type: String,
                default: undefined,
            },
        },

        data: () => ({
            dataset: undefined,
            status: undefined,
        }),

        computed: {
            request() {
                if (this.datasetId !== undefined) {
                    return () => DatastoreAPI.getDataset(this.datasetId);
                }
                return undefined;
            },
        },

        methods: {
            onRequestSuccess(response) {
                this.dataset = response.data;
                this.status = response.status;
            },
        },
    };
</script>
