import constants from '@/constants';
import webapi from '@/core/webapi';

const BASE_URI = `${constants.API_BASE}/report/datastore`;

export default class DatastoreAPI {
    static async list() : Promise<any> {
        const uri = `${BASE_URI}`;
        return webapi.get({ uri });
    }

    static async getDataset(datasetId: string) : Promise<any> {
        const uri = `${BASE_URI}/${datasetId}`;
        return webapi.get({ uri });
    }

    static async deleteDataset(datasetId: string) : Promise<any> {
        const uri = `${BASE_URI}/${datasetId}`;
        return webapi.delete({ uri });
    }

    static async uploadDataset(
        name: string, client: string, datatype: string, file: Blob,
    ) : Promise<any> {
        const uri = `${BASE_URI}`;

        const body = new FormData();
        body.append('name', name);
        body.append('client', client);
        body.append('datatype', datatype);
        body.append('file', file);

        return webapi.upload({ uri, body });
    }
}
