const splitCamelCase = (s: string) : any => s.split(/([A-Z][a-z]+)/).filter((e: string) => e).join(' ');

export default class ModelTable {
    rawColumns: Array<string>;

    rawRows: Array<Record<string, any>>

    constructor(dataset: Record<string, any>) {
        this.rawColumns = dataset.columns;
        this.rawRows = dataset.rows;
    }

    get headers() : Array<Record<string, string | Boolean>> {
        const headers = this.rawColumns.map((item) => ({
            text: splitCamelCase(item),
            value: item,
            sortable: true,
            align: 'start',
        }));

        return headers;
    }

    get rows() : Array<Record<string, any>> {
        return this.rawRows;
    }
}
